/***"build": "react-scripts build",**/

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 12.5px;
	--color-text: #777674;
	/* --color-bg: #2a0af6; */
	--color-link: #777674;
	--color-link-hover: #f4d88c;
	--page-padding: 1.5rem;
	--color-link-works: #fff;
	--color-link-works-hover: #f4d88c;
	--color-link-works-current: #eeae30;
	--color-title: #fff;
}

body {
	margin: 0;
	color: var(--color-text);
	/* background-color: var(--color-bg); */
	font-family: "Onest", sans-serif;
  font-optical-sizing: auto;
	text-transform: uppercase;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 10000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: underline;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

a:hover {
	text-decoration: none;
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	cursor: pointer;
}

.unbutton:focus {
	outline: none;
}

.frame {
	padding: var(--page-padding);
	position: relative;
	min-height: 100vh;
	display: grid;
	z-index: 1000;
	width: 100%;
	grid-row-gap: 1rem;
	grid-column-gap: 2rem;
	justify-items: start;
	grid-template-columns: auto auto auto;
	grid-template-areas: 
		'site year menu'
	  'tagline tagline tagline'
		'contact contact contact'
		'works works works'
		'content content content'
		'title title title'
		'links links links';
}

/* .mai-std{
	position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  z-index: -1;
  grid-template-areas: 'background';
  grid-template-columns: 100%;

  grid-template-rows: 100%;
  pointer-events: none;
  place-items: center;
} */

.frame a {
  pointer-events: auto;
}

.frame__title {
  grid-area: title;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
	align-self: end;
}

.frame__title h1 {
	font-size: inherit;
	margin: 0;
	font-weight: inherit;
}

.frame__tagline {
	grid-area: tagline;
	max-width: 220px;
	line-height: 1.4;
}

.frame__site {
	grid-area: site;
}

.frame__year {
	grid-area: year;
}

.frame__contact {
	grid-area: contact;
}

.frame__works {
	grid-area: works;
	display: flex;
	flex-direction: column;
	pointer-events: none;
}

.frame__works span {
	margin-bottom: 1.5rem;
}

.frame__works a {
	pointer-events: auto;
	padding: 0.25rem 0;
	position: relative;
	font-size: 1.5rem;
	text-decoration: none;
	color: var(--color-link-works);
}

.frame__works a:hover {
	color: var(--color-link-works-hover);
}
 
.frame__works a.current {
	color: var(--color-link-works-current);
}

.frame__sponsors {
	grid-area: sponsors;
}

.frame__menu {
	grid-area: menu;
	justify-self: end;
	width: 30px;
	aspect-ratio: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: self-end;
	gap: 0.5rem;
}

.frame__menu::before {
	content: '';
	width: 100%;
	height: 1px;
	background: currentColor;
}

.frame__menu::after {
	content: '';
	width: 60%;
	height: 1px;
	background: currentColor;
}

.frame__links {
	grid-area: links;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;	
	align-self: end;
}

.frame__content {
	grid-area: content;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-areas: 'content-item';
	position: relative;
  overflow: hidden;
}

.frame__title-main {
	text-transform: none;
	line-height: 0.9;
	font-weight: 400;
	margin: 80px 0px 0px 0px;
	grid-area: content;
	color: var(--color-title);
	font-size: clamp(2rem,8vw,9rem);
	display: grid;
	align-content: center;
	width: 100%;
	pointer-events: none;
}

.frame__title-main span:last-child {
	margin-left: 1em;
}

.content {
	pointer-events: none;
	position: relative;
	opacity: 0;
	grid-area: content-item;
	display: grid;
	grid-template-columns: repeat(10,1fr);
	grid-template-rows: repeat(10,1fr);
	width: 100%;
	height: 100%;
	z-index: 0;
}

.content--current {
	opacity: 1;
}

.content__title {
	position: relative;
	z-index: 10;
	text-transform: none;
	font-weight: normal;
	opacity: 0;
	grid-area: 1 / 2 / -1 / -2;
	width: 100%;
	height: 100%;
	display: grid;
	align-content: center;
	margin: 0;
	line-height: 1;
	color: var(--color-title);
	font-size: clamp(2rem,10vw,9rem);
}

.no-js .content__title {
	opacity: 1;
}

.content__img {
	position: relative;
	will-change: clip-path, filter;
	width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
}

.content__img-inner {
	background-size: cover;
	background-position: 50% 0%;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
  transition: transform 0.3s ease;
}

.pos-1 { grid-area: 1 / 1 / 5 / 5; }
.pos-2 { grid-area: 5 / 8 / 10 / 11; }
.pos-3 { grid-area: 8 / 3 / 11 / 5; }

.pos-4 { grid-area: 3 / 5 / 8 / 10; }
.pos-5 { grid-area: 7 / 4 / 10 / 7; }
.pos-6 { grid-area: 2 / 2 / 4 / 4; }

.pos-7 { grid-area: 8 / 2 / 11 / 5; }
.pos-8 { grid-area: 2 / 8 / 8 / 11; }
.pos-9 { grid-area: 3 / 3 / 6 / 6; }

.pos-10 { grid-area: 7 / 7 / 10 / 9; }
.pos-11 { grid-area: 4 / 1 / 10 / 4; }
.pos-12 { grid-area: 2 / 5 / 6 / 9; }

.pos-13 { grid-area: 3 / 8 / 8 / 11; }
.pos-14 { grid-area: 1 / 5 / 5 / 7; }
.pos-15 { grid-area: 6 / 2 / 11 / 5; }

.background {
	position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  z-index: -1;
  /* grid-template-areas: 'background'; */
  grid-template-columns: 100%;

  grid-template-rows: 100%;
  pointer-events: none;
  place-items: center;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease;
  opacity: 1;
    
}
.black{
	background-color: black;
	color: white !important;
}
.Cultured{
	background-color: #F7F6F2;
	color: black !important;
}
.Alabaster{
	background-color: #F4ECE3;
	color: black !important;
}
.DarkRed{
	background-color: #750300;
	color: white !important;
}

.background__image {
	position: relative;
	z-index: 0;
	/* grid-area: background; */
	background-size: cover;
	filter: brightness(1);
	width: 120%;
	height: 120%;
	opacity: 0;
	/* mix-blend-mode: multiply; */

}

.image-bg-contain{
	width: 50%;
}

.background__video {
	/* position: absolute; */
	z-index: 1;
  /* grid-area: background; */
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 100%;
  /* mix-blend-mode: color-burn; */
  margin: 0px 0px 0px 0px;

}

@media screen and (min-width: 53em) {
	body {
		--page-padding: 2.5rem;
	}
	.frame {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		grid-template-columns: 20% 15% 30% 1fr 1fr;
		grid-template-rows: auto auto 1fr 1fr auto;
		align-content: space-between;
		grid-template-areas: 
		'tagline ... site year menu'
		'tagline content content content content'
		'contact content content content content'
		'works content content content content'
		'title title links links links';
	}
	.frame__contact {
		padding-top: 3rem;
	}
	.frame__links {
		gap: 2rem;
	}
	.frame__works {
    margin-bottom: 10vh;
  }
	.frame__title-main {
		grid-area: 5 / 3 / 2 / 6;
	}
}
