.privacy-policy {
    font-family: Arial, sans-serif;
    width: 65%;
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   text-transform: capitalize;
  }
  
  .privacy-policy h1,
  .privacy-policy h2 {
    color: #4a4a4a;
  }
  
  .privacy-policy p {
    margin-bottom: 20px;
  }
  
  .privacy-policy a {
    color: #4a4a4a;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  
  .privacy-policy .disclaimer {
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .privacy-policy section {
    margin-bottom: 40px;
  }
  