@import url(https://fonts.googleapis.com/css?family=Yantramanav:100,300);
/***"build": "react-scripts build",**/

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 12.5px;
	--color-text: #777674;
	/* --color-bg: #2a0af6; */
	--color-link: #777674;
	--color-link-hover: #f4d88c;
	--page-padding: 1.5rem;
	--color-link-works: #fff;
	--color-link-works-hover: #f4d88c;
	--color-link-works-current: #eeae30;
	--color-title: #fff;
}

body {
	margin: 0;
	color: var(--color-text);
	/* background-color: var(--color-bg); */
	font-family: "Onest", sans-serif;
  font-optical-sizing: auto;
	text-transform: uppercase;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 10000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: underline;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

a:hover {
	text-decoration: none;
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	cursor: pointer;
}

.unbutton:focus {
	outline: none;
}

.frame {
	padding: var(--page-padding);
	position: relative;
	min-height: 100vh;
	display: grid;
	z-index: 1000;
	width: 100%;
	grid-row-gap: 1rem;
	grid-column-gap: 2rem;
	justify-items: start;
	grid-template-columns: auto auto auto;
	grid-template-areas: 
		'site year menu'
	  'tagline tagline tagline'
		'contact contact contact'
		'works works works'
		'content content content'
		'title title title'
		'links links links';
}

/* .mai-std{
	position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  z-index: -1;
  grid-template-areas: 'background';
  grid-template-columns: 100%;

  grid-template-rows: 100%;
  pointer-events: none;
  place-items: center;
} */

.frame a {
  pointer-events: auto;
}

.frame__title {
  grid-area: title;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
	align-self: end;
}

.frame__title h1 {
	font-size: inherit;
	margin: 0;
	font-weight: inherit;
}

.frame__tagline {
	grid-area: tagline;
	max-width: 220px;
	line-height: 1.4;
}

.frame__site {
	grid-area: site;
}

.frame__year {
	grid-area: year;
}

.frame__contact {
	grid-area: contact;
}

.frame__works {
	grid-area: works;
	display: flex;
	flex-direction: column;
	pointer-events: none;
}

.frame__works span {
	margin-bottom: 1.5rem;
}

.frame__works a {
	pointer-events: auto;
	padding: 0.25rem 0;
	position: relative;
	font-size: 1.5rem;
	text-decoration: none;
	color: var(--color-link-works);
}

.frame__works a:hover {
	color: var(--color-link-works-hover);
}
 
.frame__works a.current {
	color: var(--color-link-works-current);
}

.frame__sponsors {
	grid-area: sponsors;
}

.frame__menu {
	grid-area: menu;
	justify-self: end;
	width: 30px;
	aspect-ratio: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: self-end;
	grid-gap: 0.5rem;
	gap: 0.5rem;
}

.frame__menu::before {
	content: '';
	width: 100%;
	height: 1px;
	background: currentColor;
}

.frame__menu::after {
	content: '';
	width: 60%;
	height: 1px;
	background: currentColor;
}

.frame__links {
	grid-area: links;
	display: flex;
	flex-wrap: wrap;
	grid-gap: 1rem;
	gap: 1rem;	
	align-self: end;
}

.frame__content {
	grid-area: content;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-areas: 'content-item';
	position: relative;
  overflow: hidden;
}

.frame__title-main {
	text-transform: none;
	line-height: 0.9;
	font-weight: 400;
	margin: 80px 0px 0px 0px;
	grid-area: content;
	color: var(--color-title);
	font-size: clamp(2rem,8vw,9rem);
	display: grid;
	align-content: center;
	width: 100%;
	pointer-events: none;
}

.frame__title-main span:last-child {
	margin-left: 1em;
}

.content {
	pointer-events: none;
	position: relative;
	opacity: 0;
	grid-area: content-item;
	display: grid;
	grid-template-columns: repeat(10,1fr);
	grid-template-rows: repeat(10,1fr);
	width: 100%;
	height: 100%;
	z-index: 0;
}

.content--current {
	opacity: 1;
}

.content__title {
	position: relative;
	z-index: 10;
	text-transform: none;
	font-weight: normal;
	opacity: 0;
	grid-area: 1 / 2 / -1 / -2;
	width: 100%;
	height: 100%;
	display: grid;
	align-content: center;
	margin: 0;
	line-height: 1;
	color: var(--color-title);
	font-size: clamp(2rem,10vw,9rem);
}

.no-js .content__title {
	opacity: 1;
}

.content__img {
	position: relative;
	will-change: clip-path, filter;
	width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
}

.content__img-inner {
	background-size: cover;
	background-position: 50% 0%;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
  transition: transform 0.3s ease;
}

.pos-1 { grid-area: 1 / 1 / 5 / 5; }
.pos-2 { grid-area: 5 / 8 / 10 / 11; }
.pos-3 { grid-area: 8 / 3 / 11 / 5; }

.pos-4 { grid-area: 3 / 5 / 8 / 10; }
.pos-5 { grid-area: 7 / 4 / 10 / 7; }
.pos-6 { grid-area: 2 / 2 / 4 / 4; }

.pos-7 { grid-area: 8 / 2 / 11 / 5; }
.pos-8 { grid-area: 2 / 8 / 8 / 11; }
.pos-9 { grid-area: 3 / 3 / 6 / 6; }

.pos-10 { grid-area: 7 / 7 / 10 / 9; }
.pos-11 { grid-area: 4 / 1 / 10 / 4; }
.pos-12 { grid-area: 2 / 5 / 6 / 9; }

.pos-13 { grid-area: 3 / 8 / 8 / 11; }
.pos-14 { grid-area: 1 / 5 / 5 / 7; }
.pos-15 { grid-area: 6 / 2 / 11 / 5; }

.background {
	position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  z-index: -1;
  /* grid-template-areas: 'background'; */
  grid-template-columns: 100%;

  grid-template-rows: 100%;
  pointer-events: none;
  place-items: center;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease;
  opacity: 1;
    
}
.black{
	background-color: black;
	color: white !important;
}
.Cultured{
	background-color: #F7F6F2;
	color: black !important;
}
.Alabaster{
	background-color: #F4ECE3;
	color: black !important;
}
.DarkRed{
	background-color: #750300;
	color: white !important;
}

.background__image {
	position: relative;
	z-index: 0;
	/* grid-area: background; */
	background-size: cover;
	filter: brightness(1);
	width: 120%;
	height: 120%;
	opacity: 0;
	/* mix-blend-mode: multiply; */

}

.image-bg-contain{
	width: 50%;
}

.background__video {
	/* position: absolute; */
	z-index: 1;
  /* grid-area: background; */
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 100%;
  /* mix-blend-mode: color-burn; */
  margin: 0px 0px 0px 0px;

}

@media screen and (min-width: 53em) {
	body {
		--page-padding: 2.5rem;
	}
	.frame {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		grid-template-columns: 20% 15% 30% 1fr 1fr;
		grid-template-rows: auto auto 1fr 1fr auto;
		align-content: space-between;
		grid-template-areas: 
		'tagline ... site year menu'
		'tagline content content content content'
		'contact content content content content'
		'works content content content content'
		'title title links links links';
	}
	.frame__contact {
		padding-top: 3rem;
	}
	.frame__links {
		grid-gap: 2rem;
		gap: 2rem;
	}
	.frame__works {
    margin-bottom: 10vh;
  }
	.frame__title-main {
		grid-area: 5 / 3 / 2 / 6;
	}
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
}

.container {
    width: 65%;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   text-transform: capitalize;

}

.terms {
    color: #4a4a4a;
    font-size: 1.8em;
    margin-bottom: 0.5em;
    text-align: left;
}

p {
    font-size: 1.2em;
    margin-bottom: 1em;
    color: #555;
    text-transform: capitalize;
}

.terms-list {
    /* list-style: disc; */
    padding: 0;
   
}

.terms-list li {
    padding: 10px 0;
    /* border-bottom: 1px solid #ddd; */
    font-size: 1em;
    color: #666;
    text-transform: capitalize
}

.terms-list li:last-child {
    border-bottom: none;
}

.privacy-policy {
    font-family: Arial, sans-serif;
    width: 65%;
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   text-transform: capitalize;
  }
  
  .privacy-policy h1,
  .privacy-policy h2 {
    color: #4a4a4a;
  }
  
  .privacy-policy p {
    margin-bottom: 20px;
  }
  
  .privacy-policy a {
    color: #4a4a4a;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  
  .privacy-policy .disclaimer {
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .privacy-policy section {
    margin-bottom: 40px;
  }
  
.cancellation-policy {
    width: 60%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    text-transform: capitalize;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   padding: 30px;

  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  details {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  summary {
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  p {
    margin-top: 10px;
    color: #555;
  }
  
* {
    font-family: Nunito, sans-serif;
  }
  
  .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 25px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .responsive-cell-block {
    min-height: 75px;
  }
  
  .responsive-container-block {
    min-height: 75px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    justify-content: flex-start;
  }
  
  .responsive-container-block.bigContainer {
    padding-top: 0px;
    padding-right: 50px;
    padding-bottom: 0px;
    padding-left: 50px;
    margin-top: 50px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
  }
  
  .responsive-container-block.Container {
    max-width: 1320px;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
  }
  
  .mainImg {
    width: 100%;
    height: 800px;
    object-fit: cover;
  }
  
  .blueDots {
    position: absolute;
    top: 150px;
    right: 15%;
    z-index: -1;
    left: auto;
    width: 80%;
    height: 500px;
    object-fit: cover;
  }
  
  .imgContainer {
    position: relative;
    width: 48%;
  }
  
  .responsive-container-block.textSide {
    width: 48%;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    z-index: 100;
  }
  
  .text-blk.heading {
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
  
  .text-blk.subHeading {
    font-size: 18px;
    line-height: 26px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
  
  .cardImg {
    width: 31px;
    height: 31px;
  }
  
  .cardImgContainer {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(229, 229, 229);
    border-right-color: rgb(229, 229, 229);
    border-bottom-color: rgb(229, 229, 229);
    border-left-color: rgb(229, 229, 229);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 0px;
  }
  
  .text-blk.cardHeading {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  
  .text-blk.cardSubHeading {
    color: rgb(153, 153, 153);
    line-height: 22px;
  }
  
  .explore {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: white;
    background-color: rgb(244, 152, 146);
    box-shadow: rgba(244, 152, 146, 0.25) 0px 10px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: initial;
    border-right-style: initial;
    border-bottom-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    padding-top: 17px;
    padding-right: 40px;
    padding-bottom: 17px;
    padding-left: 40px;
  }
  
  .explore:hover {
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
  
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(244, 182, 176);
  }
  
  #ixvck {
    margin-top: 60px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .redDots {
    position: absolute;
    bottom: -350px;
    right: -100px;
    height: 500px;
    width: 400px;
    object-fit: cover;
    top: auto;
  }
  
  @media (max-width: 1024px) {
    .responsive-container-block.Container {
      position: relative;
      align-items: flex-start;
      justify-content: center;
    }
  
    .mainImg {
      bottom: 0px;
    }
  
    .imgContainer {
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: auto;
      width: 60%;
    }
  
    .responsive-container-block.textSide {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: auto;
      width: 70%;
    }
  
    .responsive-container-block.Container {
      flex-direction: column-reverse;
    }
  
    .imgContainer {
      position: relative;
      width: auto;
      margin-top: 0px;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
    }
  
    .responsive-container-block.textSide {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 20px;
      margin-left: 0px;
      width: 100%;
    }
  
    .responsive-container-block.Container {
      flex-direction: row-reverse;
    }
  
    .responsive-container-block.Container {
      flex-direction: column-reverse;
    }
  }
  
  @media (max-width: 768px) {
    .responsive-container-block.textSide {
      width: 100%;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  
    .text-blk.subHeading {
      text-align: center;
      font-size: 17px;
      max-width: 520px;
    }
  
    .text-blk.heading {
      text-align: center;
    }
  
    .imgContainer {
      opacity: 0.8;
    }
  
    .imgContainer {
      height: 500px;
    }
  
    .imgContainer {
      width: 30px;
    }
  
    .responsive-container-block.Container {
      flex-direction: column-reverse;
    }
  
    .responsive-container-block.Container {
      flex-wrap: nowrap;
    }
  
    .responsive-container-block.textSide {
      width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 20px;
      margin-left: 0px;
    }
  
    .imgContainer {
      width: 90%;
    }
  
    .imgContainer {
      height: 450px;
      margin-top: 5px;
      margin-right: 33.9062px;
      margin-bottom: 0px;
      margin-left: 33.9062px;
    }
  
    .redDots {
      display: none;
    }
  
    .explore {
      font-size: 16px;
      line-height: 14px;
    }
  }
  
  @media (max-width: 500px) {
    .imgContainer {
      position: static;
      height: 450px;
    }
  
    .mainImg {
      height: 100%;
    }
  
    .blueDots {
      width: 100%;
      left: 0px;
      top: 0px;
      bottom: auto;
      right: auto;
    }
  
    .imgContainer {
      width: 100%;
    }
  
    .responsive-container-block.textSide {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .responsive-container-block.Container {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      overflow-x: visible;
      overflow-y: visible;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding: 0 30px 0 30px;
    }
  
    .redDots {
      display: none;
    }
  
    .text-blk.subHeading {
      font-size: 16px;
      line-height: 23px;
    }
  
    .text-blk.heading {
      font-size: 28px;
      line-height: 28px;
    }
  
    .responsive-container-block.textSide {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 50px;
      margin-left: 0px;
    }
  
    .imgContainer {
      margin-top: 5px;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
      width: 100%;
      position: relative;
    }
  
    .explore {
      padding-top: 17px;
      padding-right: 0px;
      padding-bottom: 17px;
      padding-left: 0px;
      width: 100%;
    }
  
    #ixvck {
      width: 90%;
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      font-size: 15px;
    }
  
    .blueDots {
      bottom: 0px;
      width: 100%;
      height: 80%;
      top: 10%;
    }
  
    .text-blk.cardHeading {
      font-size: 16px;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 8px;
      margin-left: 0px;
      line-height: 25px;
    }
  
    .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12 {
      padding-top: 10px;
      padding-right: 0px;
      padding-bottom: 10px;
      padding-left: 0px;
    }
  }
/* ------------- */
/* GLOBAL STYLES */
/* ------------- */

* {
  box-sizing: border-box;
}

body {
  background: #ffffff;
  color: #485e74;
  line-height: 1.6;
  font-family: 'Yantramanav', sans-serif;
  padding: 1em;
  text-transform: capitalize;

}

.container1 {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  text-transform: capitalize;

}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

.brand span {
  color: #ffffff;
}

.wrapper {
  box-shadow: 0 0 20px 0 rgba(57, 82, 163, 0.7);
}

.wrapper > * {
  padding: 1em;
}

/* ------------------- */
/* COMPANY INFORMATION */
/* ------------------- */

.company-info {
  background: #efeff1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.company-info h3,
.company-info ul {
  text-align: center;
  margin: 0 0 1rem 0;
}

/* ------- */
/* CONTACT */
/* ------- */

.contact {
  background: #f6f6f6;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* ---- */
/* FORM */
/* ---- */

.contact form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.contact form label {
  display: block;
}

.contact form p {
  margin: 0;
}

.contact form .full {
  grid-column: 1 / 3;
}

.contact form button,
.contact form input,
.contact form textarea {
  width: 100%;
  padding: 1em;
  border: solid 1px #627EDC;
  border-radius: 4px;
}

.contact form textarea {
  resize: none;
}

.contact form button {
  background: #627EDC;
  border: 0;
  color: #e4e4e4;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.contact form button:hover,
.contact form button:focus {
  background: #3952a3;
  color: #ffffff;
  outline: 0;
  transition: background-color 2s ease-out;
}

/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@media only screen and (min-width: 700px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .wrapper > * {
    padding: 2em;
  }

  .company-info {
    border-radius: 4px 0 0 4px;
  }

  .contact {
    border-radius: 0 4px 4px 0;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
}
.main-din{
    display: flex;
    /* align-items: center;
    justify-content: center; */
    min-height: 20vh;
    /* background: #c1c4d3; */
    padding: 20px;
    width: 100%;
}

.wrapper1{
    /* max-width: 1000px; */
    width: 100%;
    /* display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-evenly;
    gap: 15px; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 15px; /* Column gap */
    grid-row-gap: 0px; 
}

@media (max-width: 1200px) {
    .wrapper1 {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
    
        grid-column-gap: 15px; /* Column gap */
        grid-row-gap: 0px; 
    
    }
}
@media (max-width: 768px) {
    .wrapper1 {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for small screens */
        grid-column-gap: 15px; /* Column gap */
        grid-row-gap: 0px; 
    
    }
}

@media (max-width: 480px) {
    .wrapper1 {
        grid-template-columns: 1fr; /* 1 column for extra small screens */
        grid-column-gap: 6px; /* Column gap */
        grid-row-gap: 0px; 
    
    }
}

.wrapper1 .table{
    background: #bff8f8;
    width: 300px;
    padding: 30px 30px;
    position: relative;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
    border-radius: 10px;
}

@media (max-width: 1020px){
    .wrapper1 .table{
        /* width: calc(50% - 20px); */
        margin-bottom: 40px;
    }
}

@media (max-width: 698px){
    .wrapper1 .table{
        width: 100%;
    }
}

.table .price-section{
   display: flex;
   justify-content: center;
}

.price-section .price-area{
    height: 120px;
    width: 120px;
    background: #ffd861;
    border-radius: 50%;
    padding: 2px;
}

.price-section .price-area .inner-area{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 3px solid #fff;
    color: #fff;
    line-height: 117px;
    text-align: center;
    position: relative;
}

.price-area .inner-area .text{
    font-size: 25px;
    font-weight: 400;
    position: absolute;
    top: -10px;
    left: 17px;
}

.price-area .inner-area .price{
    font-size: 55px;
    font-weight: 500;
}

.table .package-name{
    width: 100%;
    height: 2px;
    background: #ffecb3;
    margin: 35px 0;
    position: relative;
}

.table .package-name::before{
    position: absolute;
    content: "Basic";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    font-size: 25px;
    padding: 0 10px;
    font-weight: bolder;
}

.table .features li{
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.features li .list-name{
    font-size: 17px;
    font-weight: 400;
}

.features li .icon{
    font-size: 15px;
}

.features li .icon.check{
    color: #2db94d;
}

.features li .icon.cross{
    color: #cd3241;
}

.table .btn{
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

.table .btn button{
    width: 80%;
    height: 50px;
    font-weight: 700;
    color: #fff;
    font-size: 20px;
    border: none;
    outline: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.basic .price-area,
.basic .inner-area{
    background: #ffd861;
}

.basic .btn button{
    background: #fff;
    color: #ffd861;
    border: 2px solid #ffd861;
}

.basic .btn button:hover{
    border-radius: 6px;
    background: #ffd861;
    color: #fff;
}

.Premium .price-area,
.Premium .inner-area{
    background: #a26bfa;
}

.Premium .btn button{
    background: #fff;
    color: #a26bfa;
    border: 2px solid #a26bfa;
}

.Premium .btn button:hover{
    border-radius: 6px;
    background: #a26bfa;
    color: #fff;
}

.Ultimate .price-area,
.Ultimate .inner-area{
    background: #43ef8b;
}

.Ultimate .btn button{
    background: #fff;
    color: #43ef8b;
    border: 2px solid #43ef8b;
}

.Ultimate .btn button:hover{
    border-radius: 6px;
    background: #43ef8b;
    color: #fff;
}

.basic .package-name{
    background: #ffecb3;
}

.Premium .package-name{
    background: #a26bfa;
}

.Ultimate .package-name{
    background: #43ef8b;
}

.basic .package-name::before{
    content: "Basic";
}

.Premium .package-name::before{
    content: "Premium";
}

.Ultimate .package-name::before{
    content: "Ultimate";
}

.basic ::selection,
.basic .price-area,
.basic .inner-area{
    background: #ffd861;
}

.Premium ::selection,
.Premium .price-area,
.Premium .inner-area{
    background: #a26bfa;
}

.Ultimate ::selection,
.Ultimate .price-area,
.Ultimate .inner-area{
    background: #43ef8b;
}

