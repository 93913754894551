.cancellation-policy {
    width: 60%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    text-transform: capitalize;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   padding: 30px;

  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  details {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  summary {
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  p {
    margin-top: 10px;
    color: #555;
  }
  