body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
}

.container {
    width: 65%;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   text-transform: capitalize;

}

.terms {
    color: #4a4a4a;
    font-size: 1.8em;
    margin-bottom: 0.5em;
    text-align: left;
}

p {
    font-size: 1.2em;
    margin-bottom: 1em;
    color: #555;
    text-transform: capitalize;
}

.terms-list {
    /* list-style: disc; */
    padding: 0;
   
}

.terms-list li {
    padding: 10px 0;
    /* border-bottom: 1px solid #ddd; */
    font-size: 1em;
    color: #666;
    text-transform: capitalize
}

.terms-list li:last-child {
    border-bottom: none;
}
